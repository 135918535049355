import * as i0 from '@angular/core';
import { InjectionToken, Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { isObservable } from 'rxjs';
const TK_CONFIG = new InjectionToken('Config');
class AcceptJSService {
  constructor(config, document) {
    this.document = document;
    this.activeConfig = null;
    // does accept already exist?
    const acceptExists = typeof Accept === 'function';
    if (isObservable(config)) {
      if (acceptExists) {
        throw new Error('Accept already exists');
      }
      this.unsubscribeConfig = config.subscribe(nConfig => {
        this.activeConfig = nConfig;
        this.removeAcceptJsScript();
        this.addAcceptJsScript();
      });
    } else {
      this.activeConfig = config;
      if (!acceptExists) {
        this.addAcceptJsScript();
      }
    }
  }
  generatePaymentNonce(cc) {
    const secureData = {
      authData: {
        clientKey: this.activeConfig.clientKey,
        apiLoginID: this.activeConfig.apiLoginID
      },
      cardData: cc
    };
    return new Promise((resolve, reject) => {
      Accept.dispatchData(secureData, response => {
        if (response.messages.resultCode === 'Error') {
          reject(response);
          return;
        }
        const nonce = response.opaqueData.dataValue;
        resolve(nonce);
      });
    });
  }
  ngOnDestroy() {
    if (this.unsubscribeConfig) {
      this.unsubscribeConfig.unsubscribe();
    }
    this.removeAcceptJsScript();
  }
  /** removes the script tag */
  removeAcceptJsScript() {
    if (this.element) {
      const head = this.document.getElementsByTagName('head')[0];
      if (this.element.parentNode === head) {
        head.removeChild(this.element);
      }
      this.element = null;
    }
  }
  /** add the accept JS file to the window */
  addAcceptJsScript() {
    const headerEl = this.document.getElementsByTagName('head')[0];
    const element = this.document.createElement('script');
    element.src = this.activeConfig.acceptjsUrl;
    element.type = 'text/javascript';
    this.element = headerEl.appendChild(element);
  }
  static {
    this.ɵfac = function AcceptJSService_Factory(t) {
      return new (t || AcceptJSService)(i0.ɵɵinject(TK_CONFIG), i0.ɵɵinject(DOCUMENT));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: AcceptJSService,
      factory: AcceptJSService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AcceptJSService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TK_CONFIG]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [DOCUMENT]
      }]
    }];
  }, null);
})();
class CreditCard {}
class MockAcceptJSService {
  constructor() {}
  generatePaymentNonce(cc) {
    return new Promise((resolve, reject) => {
      this.lastCCPassed = cc;
      if (this.rejectReply) {
        reject(this.rejectReply);
        return;
      }
      resolve(this.nonceReply || 'ABC123456');
    });
  }
  static {
    this.ɵfac = function MockAcceptJSService_Factory(t) {
      return new (t || MockAcceptJSService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: MockAcceptJSService,
      factory: MockAcceptJSService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MockAcceptJSService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [];
  }, null);
})();

/*
 * Public API Surface of angular-accept-jswrapper
 */

/**
 * Generated bundle index. Do not edit.
 */

export { AcceptJSService, CreditCard, MockAcceptJSService, TK_CONFIG };
